/* Global Styles */
:root {
  --primary-color: #0d6efd;
  --secondary-color: #6c757d;
  --success-color: #198754;
  --info-color: #0dcaf0;
  --warning-color: #ffc107;
  --danger-color: #dc3545;
  --light-color: #f8f9fa;
  --dark-color: #212529;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  min-height: 100vh;
}

/* Layout */
.min-vh-100 {
  min-height: 100vh;
}

/* Header Styles */
.navbar-brand img {
  max-width: 100%;
  height: auto;
}

/* Page Headers */
header {
  background-color: var(--light-color);
}

/* Form Styles */
.form-control:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

/* Card Styles */
.card {
  transition: all 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

/* Button Styles */
.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-primary:hover {
  background-color: #0b5ed7;
  border-color: #0a58ca;
}

/* Footer Styles */
footer {
  margin-top: auto;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .navbar-brand img {
    width: 300px;
  }
}

@media (max-width: 576px) {
  .navbar-brand img {
    width: 250px;
  }
}