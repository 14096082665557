/* Feature Card Component */
.feature {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 4rem;
  font-size: 2rem;
}

/* Service Card Component */
.card {
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
}

.card:hover {
  transform: translateY(-10px);
}

.card-img-top {
  height: 200px;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.card:hover .card-img-top {
  transform: scale(1.05);
}

/* Team Member Component */
.team-member-img {
  transition: transform 0.3s ease;
}

.team-member-img:hover {
  transform: scale(1.05);
}

/* Testimonial Component */
.testimonial {
  position: relative;
  padding: 20px;
}

.testimonial::before {
  content: '"';
  font-size: 5rem;
  position: absolute;
  top: -20px;
  left: -10px;
  opacity: 0.1;
  font-family: Georgia, serif;
}

/* Button Hover Effects */
.btn-primary {
  transition: all 0.3s ease;
}

.btn-primary:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
} 